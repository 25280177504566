.note-popup {
	min-width: 200px;
	max-width: 300px;

	.note-content {
		@apply outline-none bg-white/10 px-3 py-2 w-full mb-2 rounded;
	}

	.note-header {
		@apply flex justify-between items-center mb-2;
	}

	.note-close {
		@apply transition-colors hover:bg-red-400 rounded-full cursor-pointer w-5 h-5 flex items-center justify-center;
	}

	.note-title {
		@apply text-lg font-semibold flex justify-between items-center;
	}
	.note-button {
		@apply w-full px-2 py-1.5 bg-red-500 transition-opacity rounded hover:opacity-80;
	}

	.leaflet-popup-content-wrapper {
		@apply bg-black/70 text-white rounded-md;
	}
	.leaflet-popup-content {
		@apply mx-3.5 my-3;
	}
	.leaflet-popup-tip {
		@apply bg-black/70;
	}
}
