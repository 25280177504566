.top-nav {
	justify-content: stretch;
	@apply absolute z-[2000]  top-0 left-0 right-0 flex bg-theme-800 h-14 py-1 px-3;
	.logo {
		background-image: url(../../../public/images/logo2.png);
		@apply w-32 h-full min-h-full m-0 bg-no-repeat bg-contain bg-center block;
	}
	.top-nav-btn {
		@apply px-2 py-1 border border-theme-500 text-white text-sm select-none my-2 mx-0.5;
	}
}
